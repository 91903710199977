import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Box, CircularProgress
} from '@mui/material'

const UserTable = () => {
    const navigate = useNavigate();
    const [Apps, setApps] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    React.useEffect(() => {
        async function fetchContactData() {
            try {
                fetch(`${process.env.REACT_APP_SERVER_URL}/all_email_contact`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: "include",
                    method: 'GET',
                }).then(response => {
                    if (response.status === 200) {
                        return response.json()
                    }
                    else if (response.status === 202) {
                        setIsLoaded(true);
                        console.log("token expired")
                        navigate('/login', { state: { sessionExpired: true } })
                        return;
                    }
                    else {
                        setIsLoaded(true);
                        console.log("some error")
                        return response.json()
                    }
                })
                    .then(data => {
                        setApps(data.data)
                        setIsLoaded(true);
                    })
                    .catch(error => console.log(error))
            } catch (error) {
                console.log(error);
                setIsLoaded(true);
            }
        }
        fetchContactData();
    } , [navigate])
    return (
        <div style={{ marginTop: '20px' }}>
            {
                !isLoaded && 
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {   
                isLoaded && 
                <>
                    <div style={{ minHeight: '100px' }}>
                        <TableContainer>
                            <Table aria-label="sticky table" style={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Created Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Apps.map((app, i) => (
                                            <TableRow hover role="checkbox" key={i} style={{ cursor: 'pointer' }} onClick={() => { }}>
                                                <TableCell align='left'>
                                                    {app.email}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {(app.create_time ? app.create_time.split('|')[0] : '')}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>
            }
        </div>
    );
};

export default UserTable;
