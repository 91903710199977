import React from 'react'
import { Box, CircularProgress, Snackbar, Alert, Grid, Card, Typography } from '@mui/material'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode';
import cookie from 'cookiejs';
import { IoWallet } from "react-icons/io5";
import { FaCircleCheck, FaMoneyCheckDollar, FaRegFaceSmile } from "react-icons/fa6";
import LineChart from '../helper/Charts/LineCharts/LineCharts.js';
import BarChart from '../helper/Charts/BarCharts/BarChart.js';
import { totalExpenselineChartOptionsDashboard } from "../helper/Charts/LineCharts/totalExpenselineChartOptionsDashboard.js";

export const PieOptions = {
  title: "Your Total Spend",
  is3D: true,
  // colors: ['#f6c7b6', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6','#e5e4e2','#b87333']addsaasform,
};

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Home = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(null);
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [loadPieChartData, setLoadPieChartData] = React.useState(false);
  const [dashboardCompleteData, setDashboardCompleteData] = React.useState({});
  const [activeCount, setActiveCount] = React.useState(0);
  const [activeCustomers, setActiveCustomers] = React.useState(0);
  const [mostExpensive, setMostExpensive] = React.useState({});
  const [monthlyExpenseSaaSData, setMonthlyExpenseSaaSData] = React.useState([]);
  const [monthlyExpenseSaaSOption, setMonthlyExpenseSaaSOption] = React.useState({});
  const [yearlyExpenseSaaSData, setYearlyExpenseSaaSData] = React.useState([]);

  let isLogin = cookie.get("token")
  let user;
  if (!isLogin) {
    navigate("/login")
  } else {
    user = jwt(isLogin);
  }

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  }

  useEffect(() => {                        //process the piechart
    setPageLoaded(false);
    let isLogin = cookie.get("token")
    if (!isLogin) navigate("/login")

    fetch(`${REACT_APP_SERVER_URL}/dashboardData`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'GET',
    }).then(response => {
      if (response.status === 200) {
        return response.json()
      }
      else if (response.status === 202) {
        setPageLoaded(true);
        setErrorMessage("Session Expired");
        setErrorSnackbarOpen(true)
        navigate('/login');
      } else {
        setErrorMessage("Something went wrong");
        setErrorSnackbarOpen(true)
      }
    }).then(data => {
      if(data.showData){
        setLoadPieChartData(true);
        setPageLoaded(true);
        setDashboardCompleteData(data);
        setActiveCount(data['activeCount'][0].count);
        setMostExpensive(data.rExp);
        setActiveCustomers(data['activeUsers']);
        setMonthlyExpenseSaaSData(data['r3MarketData'])
        setMonthlyExpenseSaaSOption({
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            style: {
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
            onDatasetHover: {
              style: {
                fontSize: "10px",
                fontFamily: "Plus Jakarta Display",
              },
            },
            theme: "light",
            y: {
              formatter: function (val) {
                return `${user.currentRegion === 2 ? '$' : '₹'}` + val
              }
            }
          },
          xaxis: {
            categories: data['r3MarketPlace'],
            show: true,
            labels: {
              show: true,
              style: {
                color: '#000',
                fontSize: "10px",
              },
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          yaxis: {
            show: true,
            color: "#000",
            labels: {
              show: true,
              style: {
                colors: "#000",
                fontSize: "10px",
                fontFamily: "Plus Jakarta Display",
              },
            },
          },
          grid: {
            show: true,
          },
          fill: {
            opacity: 1
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
              columnWidth: "12px",
            },
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                plotOptions: {
                  bar: {
                    borderRadius: 0,
                  },
                },
              },
            },
          ],
        })
        //Year data
        if(data['r4MarketData'].length){
          setYearlyExpenseSaaSData(data['r4MarketData'])
        }
      } else {
        setLoadPieChartData(false);
        setPageLoaded(true);
      }
    })
      .catch(error => console.log(error))
      // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <Box sx={{mt: 3}}>
        {!pageLoaded &&
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
        {
          pageLoaded && loadPieChartData &&
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} xl={3} sx={{ pt: '0%!important' }}>
                  <Box sx={{ width: '100%', padding: '4% 8%', display: 'flex', height: '15vh', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <div>
                      <p style={{ fontWeight: 'bold', margin: '1% 0%', paddingLeft: '2px' }}>Total Sale</p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: '20px', margin: '2% 2%' }}>{user.currentRegion === 2 ? '$' : '₹'}{parseFloat(dashboardCompleteData['totalSpend']).toFixed(2)}</p>
                      </div>
                    </div>
                    <div>
                      <IoWallet size="30px" color="blue" />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} xl={3} sx={{ pt: '0%!important' }}>
                  <Box sx={{ width: '100%', padding: '4% 8%', display: 'flex', height: '15vh', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <div>
                      <p style={{ fontWeight: 'bold', margin: '1% 0%' }}>Monthly Recurring Sale</p>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '20px', margin: '2% 0%' }}>{user.currentRegion === 2 ? '$' : '₹'}{mostExpensive ? mostExpensive['total_spends'] ? parseFloat(mostExpensive['total_spends']).toFixed(2) : 0 : 0}</p>
                      </div>
                    </div>
                    <div>
                      <FaRegFaceSmile size="30px" color="orange" />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} xl={3} sx={{ pt: '0%!important' }}>
                  <Box sx={{ width: '100%', padding: '4% 8%', display: 'flex', height: '15vh', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <div>
                      <p style={{ fontWeight: 'bold', margin: '1% 0%' }}>Total Users:</p>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: '20px', margin: '2% 0%' }}>{activeCount}</p>
                      </div>
                    </div>
                    <div>
                      <FaMoneyCheckDollar size="30px" color="red" />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} xl={3} sx={{ pt: '0%!important' }}>
                  <Box sx={{ width: '100%', padding: '4% 8%', display: 'flex', height: '15vh', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <div>
                      <p style={{ fontWeight: 'bold', margin: '1% 0%' }}>Active Customers</p>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: '20px', margin: '2% 2%' }}>{activeCustomers}</p>
                      </div>
                    </div>
                    <div>
                      <FaCircleCheck size="30px" color="green" />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ width: '49%', pt: 1 }}>
                <Card sx={() => ({
                  height: "450px",
                  py: "32px",
                  px: '32px',
                  width: '100%',
                  borderRadius: '10px',
                  margin: '2% 0%',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                })}>
                  <Box sx={{ height: "100%" }}>
                    <Typography variant="lg" color="black" fontWeight="bold" mb="5px">
                      Total Sale VS Month
                    </Typography>
                    <Box display="flex" alignItems="center" mb="40px" justifyContent="space-between">
                      <Typography variant="button" color="success" fontWeight="bold">
                        <Typography variant="button" color="text" fontWeight="regular">
                          &nbsp;in {(new Date()).toLocaleString('default', { year: 'numeric' })}
                        </Typography>
                      </Typography>
                      <Typography>*all amounts are in {user.currentRegion === 2 ? 'USD' : 'INR'}</Typography>
                    </Box>
                    <Box sx={{ height: "310px" }}>
                      <LineChart
                        lineChartData={yearlyExpenseSaaSData}
                        lineChartOptions={totalExpenselineChartOptionsDashboard}
                      />
                    </Box>
                  </Box>
                </Card>
              </Box>
              <Box sx={{ width: '49%', pt: 1 }}>
                <Card sx={() => ({
                  height: "450px",
                  py: "32px",
                  px: '32px',
                  width: '100%',
                  borderRadius: '10px',
                  margin: '2% 0%',
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                })}>
                  <Box sx={{ height: "100%" }}>
                    <Typography variant="lg" color="black" fontWeight="bold" mb="5px">
                      Total Sale VS Software
                    </Typography>
                    <Box display="flex" alignItems="center" mb="40px" justifyContent="space-between">
                      <Typography variant="button" color="success" fontWeight="bold">
                        This Month
                      </Typography>
                      <Typography>*all amounts are in {user.currentRegion === 2 ? 'USD' : 'INR'}</Typography>
                    </Box>
                    <Box sx={{ height: "310px" }}>
                      <BarChart
                        barChartData={monthlyExpenseSaaSData}
                        barChartOptions={monthlyExpenseSaaSOption}
                      />
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Box>
          </>
        }
      </Box>
      <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Home

