import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Box, CircularProgress,
    Backdrop, Stack, Button, TextField
} from '@mui/material'

const UserTable = () => {
    const navigate = useNavigate();
    const wrapperRef = React.useRef(null);
    const [Apps, setApps] = React.useState({});
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currentApp, setCurrentApp] = React.useState({});
    const [settingDialougeOpen, setSettingDialougeOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');

    React.useEffect(() => {
        async function fetchContactData() {
            try {
                fetch(`${process.env.REACT_APP_SERVER_URL}/all_refs`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: "include",
                    method: 'GET',
                }).then(response => {
                    if (response.status === 200) {
                        return response.json()
                    }
                    else if (response.status === 202) {
                        setIsLoaded(true);
                        console.log("token expired")
                        navigate('/login', { state: { sessionExpired: true } })
                        return;
                    }
                    else {
                        setIsLoaded(true);
                        console.log("some error")
                        return response.json()
                    }
                })
                    .then(data => {
                        setApps(data.data)
                        setIsLoaded(true);
                    })
                    .catch(error => console.log(error))
            } catch (error) {
                console.log(error);
                setIsLoaded(true);
            }
        }
        fetchContactData();
    }, [navigate])
    function useOutAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setSettingDialougeOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const handleSettingDialogClose = () => {
        setSettingDialougeOpen(false)
    }
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    useOutAlerter(wrapperRef)
    const filteredAppsData = Object.fromEntries(Object.entries(Apps).filter(
        ([key]) => searchQuery === '' || (key.toLowerCase()).includes(searchQuery.toLowerCase())
    ));

    return (
        <div style={{ marginTop: '20px' }}>
            {
                Object.keys(currentApp).length !== 0 &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={settingDialougeOpen}
                >
                    <Stack ref={wrapperRef} gap={1} justifyContent="center" alignItems="center">
                        <Box sx={{ background: 'white', color: "black", width: "620px", p: 3, maxHeight: '700px' }}>
                            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}><h2>{currentApp.name}</h2>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "40px",
                                        height: "40px",
                                        backgroundColor: "#7953FF",
                                        color: 'white',
                                        borderRadius: "50%",
                                    }}
                                >
                                    <Button onClick={handleSettingDialogClose}
                                        sx={{ borderRadius: "50%", backgroundColor: "none", color: 'white' }}
                                    >X</Button>
                                </Box>
                            </div>
                            <TableContainer sx={{ overflow: 'auto' }}>
                                <Table aria-label="sticky table" style={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align='left'
                                                style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                            >
                                                Date
                                            </TableCell>
                                            <TableCell
                                                align='left'
                                                style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                            >
                                                Count
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (currentApp.data).map((app, i) => (
                                                <TableRow role="checkbox" key={i} style={{ cursor: 'pointer' }}>
                                                    <TableCell align='left'>
                                                        {app.date}
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        {app.count}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Box>
                    </Stack>
                </Backdrop>
            }
            <TextField
                // className={classes.searchBox}
                id="filled-basic"
                type="text"
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{ width: '30%' }}
            />
            {
                !isLoaded &&
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {
                isLoaded &&
                <>
                    <div style={{ minHeight: '100px' }}>
                        <TableContainer>
                            <Table aria-label="sticky table" style={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Count
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(filteredAppsData).map((app, i) => (
                                            <TableRow hover role="checkbox" key={i} style={{ cursor: 'pointer' }} onClick={() => { setCurrentApp(filteredAppsData[app]); setSettingDialougeOpen(true); }}>
                                                <TableCell align='left'>
                                                    {filteredAppsData[app].name}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {filteredAppsData[app].count}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>
            }
        </div>
    );
};

export default UserTable;
