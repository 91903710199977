import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Box, CircularProgress,
    Backdrop, Stack, Button,
    Typography
} from '@mui/material'

const UserTable = () => {
    const navigate = useNavigate();
    const wrapperRef = React.useRef(null);
    const [Apps, setApps] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currentApp, setCurrentApp] = React.useState(false);
    const [settingDialougeOpen, setSettingDialougeOpen] = React.useState(false)

    React.useEffect(() => {
        async function fetchContactData() {
            try {
                fetch(`${process.env.REACT_APP_SERVER_URL}/all_contact`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: "include",
                    method: 'GET',
                }).then(response => {
                    if (response.status === 200) {
                        return response.json()
                    }
                    else if (response.status === 202) {
                        setIsLoaded(true);
                        console.log("token expired")
                        navigate('/login', { state: { sessionExpired: true } })
                        return;
                    }
                    else {
                        setIsLoaded(true);
                        console.log("some error")
                        return response.json()
                    }
                })
                    .then(data => {
                        setApps(data.data)
                        setIsLoaded(true);
                    })
                    .catch(error => console.log(error))
            } catch (error) {
                console.log(error);
                setIsLoaded(true);
            }
        }
        fetchContactData();
    }, [navigate])
    function useOutAlerter(ref) {
        React.useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setSettingDialougeOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const handleSettingDialogClose = () => {
        setSettingDialougeOpen(false)
    }
    useOutAlerter(wrapperRef)
    return (
        <div style={{ marginTop: '20px' }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={settingDialougeOpen}
            >
                <Stack ref={wrapperRef} gap={1} justifyContent="center" alignItems="center">
                    <Box sx={{ background: 'white', color: "black", width: "620px", p: 3, display: "flex", justifyContent: "space-around" }}>
                        <Typography>{currentApp.message}</Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "#7953FF",
                                color: 'white',
                                borderRadius: "50%",
                            }}
                        >
                            <Button onClick={handleSettingDialogClose}
                                sx={{ borderRadius: "50%", backgroundColor: "none", color: 'white' }}
                            >X</Button>
                        </Box>
                    </Box>
                </Stack>
            </Backdrop>
            {
                !isLoaded &&
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {
                isLoaded &&
                <>
                    <div style={{ minHeight: '100px' }}>
                        <TableContainer>
                            <Table aria-label="sticky table" style={{ borderCollapse: 'separate', borderSpacing: '0px 10px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Company
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Phone
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Requested Saas
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                                        >
                                            Created Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Apps.map((app, i) => (
                                            <TableRow hover role="checkbox" key={i} style={{ cursor: 'pointer' }} onClick={() => { setCurrentApp(app); setSettingDialougeOpen(true); }}>
                                                <TableCell align='left'>
                                                    {app.firstName} {app.lastName}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {app.email}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {app.company}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {app.phone}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {app.saas}
                                                </TableCell>
                                                <TableCell align='left'>
                                                    {(app.create_time ? app.create_time.split('|')[0] : '')}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>
            }
        </div>
    );
};

export default UserTable;
