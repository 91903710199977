import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { TextField } from '@mui/material'
import { ReactComponent as NoApps } from '../../img/svg/NoApps.svg';
import './MyApps.css'
import UserTable from './MyAppsTable';

const MyApps = (props) => {
    const [myApps, setMyApps] = useState([]);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false);
    useEffect(() => {
        setIsLoaded(false);
        async function fetctAppData() {
            try {
                fetch(`${process.env.REACT_APP_SERVER_URL}/all_users`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    credentials: "include",
                    method: 'GET',
                }).then(response => {
                    if (response.status === 200) {
                        return response.json()
                    }
                    else if (response.status === 202) {
                        setIsLoaded(true);
                        console.log("token expired")
                        navigate('/login', { state: { sessionExpired: true } })
                        return;
                    }
                    else {
                        setIsLoaded(true);
                        console.log("some error")
                        return response.json()
                    }
                })
                    .then(data => {
                        setMyApps(data.data)
                        setIsLoaded(true);
                    })
                    .catch(error => console.log(error))
            } catch (error) {
                console.log(error);
                setIsLoaded(true);
            }
        }
        fetctAppData();
        // eslint-disable-next-line
    }, [navigate, isSomethingChanged]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredAppsData = myApps.filter(
        (key) => searchQuery === '' || (key['firstName'].toLowerCase()).includes(searchQuery.toLowerCase()) || (key['email'].toLowerCase()).includes(searchQuery.toLowerCase())
    );

    return (
        <div className="my-apps-container">
            {
                isLoaded &&
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2%', marginTop: '2%' }}>
                        <TextField
                            // className={classes.searchBox}
                            id="filled-basic"
                            type="text"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            sx={{ width: '30%' }}
                        />
                    </div>
                </>
            }
            {
                !isLoaded &&
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {
                isLoaded &&
                <>
                    {
                        ((Object.keys(props.userRole).length === 0) || (Object.keys(props.userRole).length !== 0 && props.userRole[0]['role'] === 'allow')) &&
                        <Box>
                            {
                                myApps.length !== 0 &&
                                <div className='app'>
                                    <UserTable Apps={myApps && filteredAppsData} isSomethingChanged={isSomethingChanged} setIsSomethingChanged={setIsSomethingChanged} />
                                </div>
                            }
                            {
                                myApps.length === 0 &&
                                <div className='app'>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '-4%' }}>
                                        <NoApps style={{ height: '50vh' }}></NoApps>
                                        <h2 style={{ margin: 0 }}>
                                            No Softwares Added
                                        </h2>
                                        <p style={{ marginTop: 0, fontSize: '12px' }}>Purchase Your First Software</p>
                                        <button className="add_new" style={{ width: '15%', height: '7vh' }} onClick={() => navigate('/dashboard/marketplace')}>
                                            Purchase
                                        </button>
                                    </Box>
                                </div>
                            }
                        </Box>
                    }
                </>
            }
        </div>
    );
};

export default MyApps;