import React, { useState } from 'react'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { OutlinedInput, FormControl, Select, CircularProgress, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import LogoutIcon from '@mui/icons-material/Logout';

import {
    Toolbar, Typography, Box,
    Button, List, ListItem, Drawer as MuiDrawer,
    Container, MenuItem, Menu
} from '@mui/material'
// import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import AppsIcon from '@mui/icons-material/Apps';
import { NavLink, useNavigate, Outlet } from 'react-router-dom'
import Applogo from '../../img/images/logo.png'
import jwt from 'jwt-decode'
import cookie from 'cookiejs';
import { Link } from 'react-router-dom';
const drawerWidth = 244;

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);
const mdTheme = createTheme({
    typography: {
        fontFamily: [
            'League Spartan',
            'sans-serif',
        ].join(','),
    }
});

const DashBoard = (props) => {
    const navigate = useNavigate();
    const [fname, setFName] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataSaved, setDataSaved] = React.useState(false);
    const [selectedRegion, setSelectedRegion] = React.useState({});
    const [allRegion, setAllRegion] = React.useState([]);

    let isLogin = cookie.get("token")
    if (!isLogin){
        navigate("/login")  
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    React.useEffect(() => {
        let isLogin = cookie.get("token")
        if (!isLogin) navigate("/login")
        else {
            const user = jwt(isLogin);
            if (user.emailverified === '0') {
                setSnackbarOpen(true);
            }
            else {
                setSnackbarOpen(false);
            }
            setFName(user.firstname);
            setIsLoading(false);
            fetch(`${REACT_APP_SERVER_URL}/get_region`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                method: 'GET',
            }).then(response => {
                if (response.status === 200) {
                    return response.json()
                }
                else if (response.status === 202) {
                    console.log("token expired")
                    navigate('/login', { state: { sessionExpired: true } })
                }
                else {
                    console.log("some error")
                }
            })
                .then(data => {
                    setAllRegion(data.data);
                    let index = data.data.findIndex(x => x.id === data.currentRegion);
                    setSelectedRegion(JSON.stringify(data.data[index]));
                    setIsLoading(true);
                })
                .catch((error) => {
                    setIsLoading(true);
                    console.log(error)
                })
        }
        // eslint-disable-next-line
    }, [dataSaved])

    const handleChange = (event) => {
        setIsLoading(false);
        const key = JSON.parse(event.target.value);
        fetch(`${REACT_APP_SERVER_URL}/set_region`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "rId": key.id
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                setDataSaved(!dataSaved);
            })
            .catch((error) => {
                setIsLoading(true);
                console.log(error)
            })
    };
    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    }
    const handleProfileMenuOpen = (event) => {
        setProfileMenuOpen(event.currentTarget);
    }
    const handleLogoutAPI = (e) => {
        e.preventDefault();
        fetch(`${REACT_APP_SERVER_URL}/logout`, {
            headers: {
                'Accept': 'application/json',
            },
            credentials: "include",
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                localStorage.clear();
                console.log('User Logged out')
                navigate("/login")
                return response.json()
            }
            else {
                console.log("Some error");
                localStorage.clear();
            }
        })
    }


    return (
        <>
            {snackbarOpen &&
                <div style={{ height: '30px', fontSize: '16px', display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D6EBFB' }}>
                    <p > Please activate your account by verifying your email address to purchase products</p>

                    <Link style={{ backgroundColor: '#7953FF', paddingLeft: '10px', paddingRight: '10px', color: '#fff', borderRadius: '5px', textDecoration: 'none', }} to='/verifyemail' target='blank'>activate</Link>

                    <Button sx={{
                        backgroundColor: '#ff3333', borderRadius: '5px', fontSize: '10px', height: '23px', px: 2, color: '#fff', fontWeight: 600, border: '1px Solid #ff3333',
                        ':hover': { color: '#ff3333', }
                    }} onClick={handleSnackbarClose}>close</Button>
                </div>
            }

            {
                !isLoading &&
                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', mt: 55 }}>
                    <CircularProgress />
                </Box>
            }
            {
                isLoading &&
                <ThemeProvider theme={mdTheme}>

                    <Box sx={{ display: 'flex',  height: '100vh', overflow: 'hidden' }}>

                        <CssBaseline />
                        <Drawer variant="permanent" open={true} sx={{ overflowX: 'hidden', background: 'red', height: '100vh' }}>
                            <Toolbar
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    px: [1],
                                    width: '96%',
                                    margin: '2%'
                                }}
                            >
                                <Typography sx={{ fontSize: '22px', fontWeight: "600", lineHeight: "28px" }} >
                                    <img src={Applogo} alt="" style={{ width: "112%", marginLeft: -18, padding: 26, cursor: 'pointer' }} onClick={() => navigate('/dashboard/home')}/>
                                </Typography>
                            </Toolbar>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                            }}>
                                <List sx={{ pl: 2.5, fontFamily: `'Inter', sans-serif`, height: 'auto'}}>
                                    <NavLink exact to='home' style={{ textDecoration: "none", color: "#4F4F4F", width: '100%' }}>
                                        <ListItem
                                            sx={{
                                                backgroundColor: window.location.pathname.split('/')[2] === 'home' ? '#F7F7F9' : 'none',
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight: window.location.pathname.split('/')[2] === 'home' ? "4px solid #7953FF" : 'none',
                                                width: "100%",
                                                fontWeight: window.location.pathname.split('/')[2] === 'home' ? '1000' : '',
                                                mb: 2
                                            }}
                                        >
                                            <GridViewIcon alt="" style={{ marginRight: 10 }} />DashBoard
                                        </ListItem>
                                    </NavLink>
                                    <NavLink exact to='myapps' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                        <ListItem
                                            sx={{
                                                backgroundColor: window.location.pathname.split('/')[2] === 'myapps' ? '#F7F7F9' : 'none',
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight: window.location.pathname.split('/')[2] === 'myapps' ? "4px solid #7953FF" : 'none',
                                                width: "100%",
                                                fontWeight: window.location.pathname.split('/')[2] === 'myapps' ? '1000' : '',
                                                mb: 2
                                            }}
                                        >
                                            <AppsIcon alt="" style={{ marginRight: 10 }} />All Users
                                        </ListItem>
                                    </NavLink>
                                    <NavLink exact to='email_contact' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                        <ListItem
                                            sx={{
                                                backgroundColor: window.location.pathname.split('/')[2] === 'email_contact' ? '#F7F7F9' : 'none',
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight: window.location.pathname.split('/')[2] === 'email_contact' ? "4px solid #7953FF" : 'none',
                                                width: "100%",
                                                fontWeight: window.location.pathname.split('/')[2] === 'email_contact' ? '1000' : '',
                                                mb: 2
                                            }}
                                        >
                                            <AppsIcon alt="" style={{ marginRight: 10 }} />Email Contact Us
                                        </ListItem>
                                    </NavLink>
                                    <NavLink exact to='contact' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                        <ListItem
                                            sx={{
                                                backgroundColor: window.location.pathname.split('/')[2] === 'contact' ? '#F7F7F9' : 'none',
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight: window.location.pathname.split('/')[2] === 'contact' ? "4px solid #7953FF" : 'none',
                                                width: "100%",
                                                fontWeight: window.location.pathname.split('/')[2] === 'contact' ? '1000' : '',
                                                mb: 2
                                            }}
                                        >
                                            <AppsIcon alt="" style={{ marginRight: 10 }} />Contact Us
                                        </ListItem>
                                    </NavLink>
                                    <NavLink exact to='ref' style={{ textDecoration: "none", color: "#4F4F4F", }}>
                                        <ListItem
                                            sx={{
                                                backgroundColor: window.location.pathname.split('/')[2] === 'ref' ? '#F7F7F9' : 'none',
                                                borderRadius: "50px 0px 0px 50px",
                                                borderRight: window.location.pathname.split('/')[2] === 'ref' ? "4px solid #7953FF" : 'none',
                                                width: "100%",
                                                fontWeight: window.location.pathname.split('/')[2] === 'ref' ? '1000' : '',
                                                mb: 2
                                            }}
                                        >
                                            <AppsIcon alt="" style={{ marginRight: 10 }} />Ref Count
                                        </ListItem>
                                    </NavLink>
                                </List>
                            </div>
                        </Drawer>
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                height: '100vh',
                                overflow: 'auto',
                            }}
                        >
                            <Container sx={{ mt: 0, maxWidth: '1300px!important' }}>
                                <Box sx={{ display: 'flex', width: '100%!importnat', justifyContent: 'space-between', mt: 2 }}>
                                    <Stack direction='row' justifyContent='space-between' alignItems="center" spacing={2} flexWrap="wrap" sx={{ width: '55%', pt: 2 }}>
                                        {
                                            window.location.pathname.split('/')[2] === 'home' &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                                <Typography
                                                    color="#000"
                                                    sx={{ float: "left", fontSize: "24px", lineHeight: "36px", fontWeight: "700", fontStyle: "normal" }}
                                                >
                                                    DashBoard
                                                </Typography>
                                                <Typography>
                                                    Get details of your recent activities. Your recenet payments, subscriptions and much more.
                                                </Typography>
                                            </Box>
                                        }
                                        {
                                            window.location.pathname.split('/')[2] === 'myapps' &&
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                                <Typography
                                                    color="#000"
                                                    sx={{ float: "left", fontSize: "24px", lineHeight: "36px", fontWeight: "700", fontStyle: "normal" }}
                                                >
                                                    My Apps
                                                </Typography>
                                                <Typography>
                                                    See and manage all the of your subscriptions from here
                                                </Typography>
                                            </Box>
                                        }
                                    </Stack>
                                    <Box sx={{ display: 'flex', width: '45%', flexDirection: 'row', alignItems: 'center' }}>
                                        <Box sx={{ width: '100%!important' }} >
                                            <Box sx={{ float: "right", display: 'flex', height: '6vh', justifyContent: 'end', pr: '-2' }}>
                                                <Box>
                                                    {
                                                        <FormControl sx={{ textAlign: 'center', height: '6vh', width: "100%" }}>
                                                            <Select
                                                                sx={{ height: '5vh' }}
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                required
                                                                value={selectedRegion}
                                                                onChange={handleChange}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                            >
                                                                {allRegion.map((obj) => (
                                                                    <MenuItem
                                                                        value={JSON.stringify(obj)}
                                                                    >
                                                                        {obj.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </Box>
                                                <Box sx={{ pt: '0.1em' }}>
                                                    <Button variant="outlined" onClick={handleProfileMenuOpen} sx={{ mx: 2, height: '5vh', mr: 0 }}>
                                                        {fname}
                                                    </Button>
                                                    <Menu
                                                        id="menu-appbar"
                                                        anchorEl={profileMenuOpen}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        keepMounted
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        open={Boolean(profileMenuOpen)}
                                                        onClose={handleProfileMenuClose}
                                                        sx={{ width: '50%', mt: 1 }}
                                                    >

                                                        <Button sx={{ mx: 2, height: '25px' }} onClick={handleLogoutAPI} endIcon={<LogoutIcon />}> Logout </Button>
                                                    </Menu>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {/* all the sections which comes below nav header */}
                                <Box sx={{ py: 2, mb: 5, mt: 3 }}>
                                    <Outlet />
                                    {/* <Routes>
                                <Route path='campaign' element={<Campaign />} />
                                <Route path='dashboard' element={<Dashboard />} />
                                <Route path='email-accounts' element={<EmailAccounts />} />
                                <Route path='integrations' element={<Integrations />} />
                                <Route path='common-mailbox' element={<CommonMailbox/>}/>
                            </Routes> */}
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                </ThemeProvider>
            }
        </>

    )
}

export default DashBoard