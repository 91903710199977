import React from 'react'
import { Link, useNavigate, } from 'react-router-dom'
import cookie from 'cookiejs';

const HomePage = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        let isLogin=cookie.get("token")
        if(!isLogin) 
            navigate("/login")
        else
            navigate("/dashboard/home")
            
    }, [navigate])
    return (
        <>
            <Link
                exact
                to="/login"
            >
                Login
            </Link>
            <br/>
            <Link
                exact
                to="/register"
            >
                Register
            </Link>
            <br/>
            <Link
                exact
                to="/dashboard/home"
            >
                DashBoard
            </Link>
            <Link
                exact
                to="/forgotPassword"
            >
                forgot password
            </Link>
            <Link
                exact
                to="/dashboard/campaign"
            >
                Campaign
            </Link>
        </>
    )
}

export default HomePage