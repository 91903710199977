import React from 'react';
import {
  Typography, Box, IconButton, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Button, 
  CircularProgress, Snackbar, Alert, Dialog, DialogTitle, DialogActions
} from '@mui/material'
import MyAppsFunctions from './MyAppsFunctions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const UserTable = ({ Apps, isSomethingChanged, setIsSomethingChanged }) => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [selectedCardName, setSelectedCardName] = React.useState("");
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [successMsg, setsuccessMsg] = React.useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
  const [updateCampaignDialougeOpen, setdUpdateCampaignDialougeOpen] = React.useState(false);
  const [errorMsg, seterrorMsg] = React.useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [editData, setEditdata] = React.useState(null)
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };
  const handleSuccessSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbarOpen(false);
  };
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const verifyUser = (id) => {
    fetch(`${REACT_APP_SERVER_URL}/verify_user`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'POST',
      body: JSON.stringify({
        id: id
      })
    }).then(response => {
      if (response.status === 200) {
        setButtonLoading(false);
        return response.json()
      }
      else if (response.status === 202) {
        setButtonLoading(false);
        navigate('/login', { state: { sessionExpired: true } })
      }
      else {
        setButtonLoading(false);
        console.log("some error")
        return response.json()
      }
    })
      .then(data => {
        if (data.status === 200) {
          setIsSomethingChanged(!isSomethingChanged)
          setsuccessMsg(data.msg);
          setSuccessSnackbarOpen(true);
        } else if (data.status === 201) {
          seterrorMsg(data.msg);
          setErrorSnackbarOpen(true);
        }
      })
      .catch((error) => { 
        setButtonLoading(false); 
        console.log(error) ;
        seterrorMsg("Something went wrong");
        setErrorSnackbarOpen(true);
      })
  }
  const handleCardClick = (cardData) => {
    setSelectedCard(cardData);
  };
  const updateCampaignClicked = (data) => {
    setEditdata(data)
    setdUpdateCampaignDialougeOpen(true);
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <Dialog
        PaperProps={{
          style: { borderRadius: "20px" }
        }}
        open={updateCampaignDialougeOpen}
      >
        <div style={{ width: "500px", height: "200px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div style={{ width: '100%' }}>
            <CloseIcon sx={{ float: 'right', color: 'black', height: '4vh', mx: 2, mt: 3, cursor: 'pointer' }} onClick={() => { setdUpdateCampaignDialougeOpen(false); }} />
          </div>
          <DialogTitle id="alert-dialog-title" sx={{ mt: 0, pt: 0 }}>
            <p style={{ color: "#EF6D6D", fontWeight: "bold", fontSize: "20px" }}>
              Update Status of Expense!!
            </p>
          </DialogTitle>
          <DialogActions sx={{ mb: 4, float: "right" }}>
            <Button onClick={() => setdUpdateCampaignDialougeOpen(false)} variant="contained" sx={{ color: "#fff" }} color='error'>Cancel</Button>
            <Button onClick={() => verifyUser(editData)} variant="contained" sx={{ color: "#fff" }} color='success'>Verify</Button>
          </DialogActions>
        </div>
      </Dialog>
      {
        selectedCard !== null &&
        <Box sx={{ width: '50%', display: 'inline-flex', mb: 4}}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: '5vh' }}>
            <Box sx={{ display: "flex", alignItems: "center", }}>
              <IconButton sx={{ float: "left" }} disabled={selectedCard === null} variant='contained' onClick={() => handleCardClick(null)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ fontSize: '18px', fontWeight: 700, float: "left" }}>{selectedCard.name}</Typography>
            </Box>
          </Box>
        </Box>
      }
      {
        selectedCard === null &&
        <>
          <div style={{ minHeight: '100px' }}>
            <TableContainer>
              <Table aria-label="sticky table" style={{borderCollapse: 'separate', borderSpacing: '0px 10px'}}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='left'
                      style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align='left'
                      style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align='left'
                      style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align='left'
                      style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '15px', fontWeight: 'bold' }}
                    >
                      Created Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Apps.map((app, i) => (
                      <TableRow hover role="checkbox" key={i} style={{cursor: 'pointer'}} onClick={() => { handleCardClick(app); setSelectedCardName(app.email); }}>
                        <TableCell align='left'>
                          {app.firstName} {app.lastName}
                        </TableCell>
                        <TableCell align='left'>
                          {app.email}
                        </TableCell>
                        <TableCell align='left'>
                          {app.email_verified === '1' ? 'Verified' : <>
                            <Button sx={{border: '0.025rem solid blue'}} onClick={() => {updateCampaignClicked(app.id); handleCardClick(null); setSelectedCardName("");}}>
                              {
                                buttonLoading && 
                                <CircularProgress size={24} />
                              }
                              {
                                !buttonLoading && 
                                'Verify'
                              }
                              </Button>
                          </>} 
                        </TableCell>
                        <TableCell align='left'>
                          {app.createdAt.split('T')[0].split("-").reverse().join("-")}
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      }
      {
        selectedCard !== null &&
        <>
          {     
            <MyAppsFunctions selectedCard={selectedCard} name={selectedCardName} />
          }
        </>
      }
      <Snackbar
        open={errorSnackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successSnackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbar}
      >
        <Alert
          onClose={handleSuccessSnackbar}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserTable;
