import React, { useState, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import DashBoard from './components/Dashboard/DashBoard';
import HomePage from './components/HomePage';
import Login from './components/Login';
import Home from './components/Home/Home';
import MyApps from './components/MyApps/MyApps';
import EmailContact from './components/EmailContact';
import Contact from './components/Contact';
import Ref from './components/Ref';

const App = () => {
  const [user, setUser] = useState("");
  const socket = useRef();
  const [userRole, setUserRole] = useState({});
  const value=(user)=>{
    setUser(user)
  }
  return (
    <>
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/login' element={<Login User={value}  />} />
        <Route path='dashboard' element={<DashBoard user={user} User={value} socket={socket} setUserRole={setUserRole}/>}>
          <Route path='myapps' element={<MyApps userRole={userRole}/>} />
          <Route path='email_contact' element={<EmailContact userRole={userRole}/>} />
          <Route path='contact' element={<Contact userRole={userRole}/>} />
          <Route path='ref' element={<Ref userRole={userRole}/>} />
          <Route path='home' element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
// https://app.easexpense.com/privacyPolicy
// https://app.easexpense.com/termsCondition