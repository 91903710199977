import React from 'react'
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import { CssBaseline, Box, Container, Grid, Typography, CircularProgress, Button, TextField, Snackbar, Alert, InputAdornment, IconButton } from '@mui/material'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import loginFrame from '../img/images/loginFrame.png'
import cookie from 'cookiejs';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from '../img/images/logo.png'; // Assuming your logo image file is in the same directory as your component file

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL

const mdTheme = createTheme({
  typography: {
    fontFamily: [
      'League Spartan',
      'sans-serif',
    ].join(','),
  }
});
const Login = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [loginLoading, setLoginLoading] = React.useState(false)
  const [sessionExpired, setSessionExpired] = React.useState(state === null ? false : state.sessionExpired)
  const [accountVerifiedMsg, setAccountVerifiedMsg] = React.useState(state === null ? "" : state.msg)
  const [showPassword, setShowPassword] = React.useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(state === null ? false : state.success)
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [verification, setVerification] = React.useState(false);

  React.useEffect(() => {
    let isLogin = cookie.get("token")
    if (isLogin)
      navigate("/")

  }, [navigate])
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarOpen(false);
  }

  function validateEmail(data) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(data);
  }

  function verify() {
    setVerification(true);

  }


  const submitHandler = (e) => {
    e.preventDefault();
    setLoginLoading(true)
    if (!validateEmail(email)) {
      setErrorSnackbarOpen(true);
      setLoginLoading(false);
      setErrorMessage('Enter valid email');
      return;
    }
    else if (password.length < 6) {
      setErrorSnackbarOpen(true);
      setLoginLoading(false);
      setErrorMessage('Enter valid password');
      return;
    } else {

      console.log(new URLSearchParams({
        'email': email,
        'password': password,
      }).toString())

      fetch(`${REACT_APP_SERVER_URL}/login`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        credentials: 'include',
        body: new URLSearchParams({
          'password': password,
          'email': email,
        })
      })
        .then(response => {
          if (response.status === 200) {
            props.User(response.data)
            setLoginLoading(false)
            navigate("/dashboard/home")
            return response.json()
          }
          else {
            setLoginLoading(false)
            setErrorSnackbarOpen(true)
            return response.json()
          }
        }).then(data => {
          console.warn("warn response of login :", data);
          props.User(data.data)
          if (data.status === 501) {
            setErrorMessage(data.msg)
            verify();
          } else if (data.status === 201) {
            setErrorMessage(data.msg)
          } else if (data.status !== 200) {
            setErrorMessage(data.msg);
          } else if(data.status === 200) {
            localStorage.setItem(
              process.env.REACT_APP_LOCALHOST_KEY,
              JSON.stringify(data.data)
            );
          }
        })
    }
  }
  const handleSessionExpiredClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSessionExpired(false);
  }
  const handleResetPasswordSuccessSnackbar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setResetPasswordSuccess(false);
  }
  const handleAccountVerifiedClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAccountVerifiedMsg("")
  }
  console.log(accountVerifiedMsg)
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />

      <Box sx={{ width: '100vw', height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ width: '25%', position: 'fixed', top: "0", left: "0" }}>
          <Link exact to="/">
            <img src={logo} alt="Logo" style={{ maxWidth: "60%", height: "10%", margin: 10 }} />
          </Link>
        </Box>
        <Container width='lg' sx={{ backgroundColor: "#fff", borderRadius: '10px', mt: 2 }}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={0} sm={0} md={0} lg={5} xl={5} sx={{ p: 3, display: "flex", justifyContent: "center", margin: 'auto' }}>
              <img src={loginFrame} alt="Login" style={{ maxWidth: "100%", height: "90%" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ p: 5, pt: 10, margin: 'auto' }}>
              <Typography sx={{ fontSize: "24px", fontWeight: "700", pb: 3 }}>
                Login Here
              </Typography>
              <Typography sx={{ fontSize: "14px", pb: 1 }}>
                Email
              </Typography>
              <TextField
                required
                placeholder='Email'
                type='email'
                name='email'
                value={email}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                sx={{ pb: 3 }}
              />
              <Typography sx={{ fontSize: "14px", pb: 1 }}>
                Password
              </Typography>
              <TextField
                required
                placeholder='Password'
                type={showPassword ? "text" : "password"}
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              <div>
                {verification && <Typography sx={{ pt: 1 }}>
                  <Link exact to='/verifyemail' style={{ textDecoration: "none", color: "#3366CC", float: "left", paddingTop: '8px' }}>
                    Click here to verify
                  </Link>
                </Typography>}
              </div>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                onClick={submitHandler}
                sx={{ mt: 1, mb: 2, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB" }}
                disabled={loginLoading}
              >
                {
                  loginLoading && <CircularProgress size={25} />
                }
                {
                  !loginLoading && "LOGIN"
                }
              </Button>

              <Typography sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography sx={{ pt: 2 }}>
                  <Link exact to='/register' style={{ textDecoration: "none", color: "#3366CC" }}>
                    Register Here
                  </Link>
                </Typography>
                <Typography sx={{ pt: 2, float: 'right' }}>
                  <Link exact to='/forgotPassword' style={{ textDecoration: "none", color: "#3366CC", float: "right", }}>
                    Forgot Password
                  </Link>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
          {errorMessage === "" ? "Some error occured" : errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={sessionExpired} autoHideDuration={6000} onClose={handleSessionExpiredClose}>
        <Alert onClose={handleSessionExpiredClose} variant="filled" severity="error" sx={{ width: '100%' }}>
          Session expired, Login again!
        </Alert>
      </Snackbar>

      <Snackbar open={accountVerifiedMsg !== ""} autoHideDuration={6000} onClose={handleAccountVerifiedClose}>
        <Alert onClose={handleAccountVerifiedClose} variant="filled" severity={accountVerifiedMsg === "account verified" ? "success" : 'error'} sx={{ width: '100%' }}>
          {accountVerifiedMsg}
        </Alert>
      </Snackbar>
      <Snackbar open={resetPasswordSuccess} autoHideDuration={6000} onClose={handleResetPasswordSuccessSnackbar}>
        <Alert onClose={handleResetPasswordSuccessSnackbar} variant="filled" severity='success' sx={{ width: '100%' }}>
          Password reset successfully
        </Alert>
      </Snackbar>
    </ThemeProvider>
  )
}

export default Login