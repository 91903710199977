import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid, Snackbar, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { IoWallet } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";
import LineChart from '../helper/Charts/LineCharts/LineCharts.js';
import BarChart from '../helper/Charts/BarCharts/BarChart.js';
import { totalExpenselineChartOptionsDashboard } from "../helper/Charts/LineCharts/totalExpenselineChartOptionsDashboard.js";
import cookie from 'cookiejs';
import jwt from 'jwt-decode';

const MyAppsFunctions = ({ selectedCard }) => {
    const navigate = useNavigate();
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const [activeCount, setActiveCount] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(null);
    const [dashboardCompleteData, setDashboardCompleteData] = React.useState({});
    const [monthlyExpenseSaaSData, setMonthlyExpenseSaaSData] = React.useState([]);
    const [monthlyExpenseSaaSOption, setMonthlyExpenseSaaSOption] = React.useState({});
    const [yearlyExpenseSaaSData, setYearlyExpenseSaaSData] = React.useState([]);
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;


    let isLogin = cookie.get("token")
    let user;
    if (!isLogin) {
        navigate("/login")
    } else {
        user = jwt(isLogin);
    }

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorSnackbarOpen(false);
    }
    const setActiveCards = (data) => {
        for (let i = 0; i < data['activeCount'].length; i++) {
            const ele = data['activeCount'][i];
            if (ele['status'] === 'true') {
                setActiveCount(ele['count'])
            }
            if (ele['status'] === 'upcoming') {
                setActiveCount(activeCount + ele['count']);
            }
            if (ele['status'] === 'Active') {
                setActiveCount(ele['count'])
            }
            if (ele['status'] === 'Upcoming') {
                setActiveCount(activeCount + ele['count']);
            }
            if (ele['status'] === 'Overdue') {
                setActiveCount(activeCount + ele['count']);
            }
        }
    }

    React.useEffect(() => {
        setPageLoaded(false);
        let isLogin = cookie.get("token")
        if (!isLogin) navigate("/login")

        fetch(`${REACT_APP_SERVER_URL}/userData`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body:JSON.stringify({
                email: selectedCard.email,
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                setPageLoaded(true);
                setErrorMessage("Session Expired");
                setErrorSnackbarOpen(true)
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");
                setErrorSnackbarOpen(true)
            }
        }).then(data => {
            setPageLoaded(true);
            setDashboardCompleteData(data);
            setActiveCards(data);
            //Month data
            setMonthlyExpenseSaaSData(data['r3MarketData'])
            setMonthlyExpenseSaaSOption({
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                tooltip: {
                    style: {
                        fontSize: "10px",
                        fontFamily: "Plus Jakarta Display",
                    },
                    onDatasetHover: {
                        style: {
                            fontSize: "10px",
                            fontFamily: "Plus Jakarta Display",
                        },
                    },
                    theme: "light",
                    y: {
                        formatter: function (val) {
                            return `${user.currentRegion === 2 ? '$' : '₹'}` + val
                        }
                    }
                },
                xaxis: {
                    categories: data['r3MarketPlace'],
                    show: true,
                    labels: {
                        show: true,
                        style: {
                            color: '#000',
                            fontSize: "10px",
                        },
                    },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                },
                yaxis: {
                    show: true,
                    color: "#000",
                    labels: {
                        show: true,
                        style: {
                            colors: "#000",
                            fontSize: "10px",
                            fontFamily: "Plus Jakarta Display",
                        },
                    },
                },
                grid: {
                    show: true,
                },
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        columnWidth: "12px",
                    },
                },
                responsive: [
                    {
                        breakpoint: 768,
                        options: {
                            plotOptions: {
                                bar: {
                                    borderRadius: 0,
                                },
                            },
                        },
                    },
                ],
            })
            //Year data
            if (data['r4MarketData'].length) {
                setYearlyExpenseSaaSData(data['r4MarketData'])
            }
        })
            .catch(error => console.log(error))
        // eslint-disable-next-line
    }, [navigate]);
    return (
        <>
            {
                !pageLoaded && 
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            }
            {
                pageLoaded && 
                <div style={{ minHeight: '100px' }}>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3} xl={3} sx={{ pt: '0%!important' }}>
                                <Box sx={{ width: '100%', padding: '4% 8%', display: 'flex', height: '15vh', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                                    <div>
                                        <p style={{ fontWeight: 'bold', margin: '1% 0%', paddingLeft: '2px' }}>Total SAAS Spend</p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p style={{ fontSize: '20px', margin: '2% 2%' }}>{user.currentRegion === 2 ? '$' : '₹'}{parseFloat(dashboardCompleteData['totalSpend']).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <IoWallet size="30px" color="blue" />
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3} xl={3} sx={{ pt: '0%!important' }}>
                                <Box sx={{ width: '100%', padding: '4% 8%', display: 'flex', height: '15vh', justifyContent: 'space-between', alignItems: 'center', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                                    <div>
                                        <p style={{ fontWeight: 'bold', margin: '1% 0%' }}>Active Subscriptions</p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p style={{ fontSize: '20px', margin: '2% 2%' }}>{activeCount}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <FaCircleCheck size="30px" color="green" />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '49%', pt: 1 }}>
                            <Card sx={() => ({
                                height: "450px",
                                py: "32px",
                                px: '32px',
                                width: '100%',
                                borderRadius: '10px',
                                margin: '2% 0%',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                            })}>
                                <Box sx={{ height: "100%" }}>
                                    <Typography variant="lg" color="black" fontWeight="bold" mb="5px">
                                        Total Expense VS Month
                                    </Typography>
                                    <Box display="flex" alignItems="center" mb="40px" justifyContent="space-between">
                                        <Typography variant="button" color="success" fontWeight="bold">
                                            <Typography variant="button" color="text" fontWeight="regular">
                                                &nbsp;in {(new Date()).toLocaleString('default', { year: 'numeric' })}
                                            </Typography>
                                        </Typography>
                                        <Typography>*all amounts are in {user.currentRegion === 2 ? 'USD' : 'INR'}</Typography>
                                    </Box>
                                    <Box sx={{ height: "310px" }}>
                                        <LineChart
                                            lineChartData={yearlyExpenseSaaSData}
                                            lineChartOptions={totalExpenselineChartOptionsDashboard}
                                        />
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Box sx={{ width: '49%', pt: 1 }}>
                            <Card sx={() => ({
                                height: "450px",
                                py: "32px",
                                px: '32px',
                                width: '100%',
                                borderRadius: '10px',
                                margin: '2% 0%',
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                            })}>
                                <Box sx={{ height: "100%" }}>
                                    <Typography variant="lg" color="black" fontWeight="bold" mb="5px">
                                        Total Expenses VS Software
                                    </Typography>
                                    <Box display="flex" alignItems="center" mb="40px" justifyContent="space-between">
                                        <Typography variant="button" color="success" fontWeight="bold">
                                            This Month
                                        </Typography>
                                        <Typography>*all amounts are in {user.currentRegion === 2 ? 'USD' : 'INR'}</Typography>
                                    </Box>
                                    <Box sx={{ height: "310px" }}>
                                        <BarChart
                                            barChartData={monthlyExpenseSaaSData}
                                            barChartOptions={monthlyExpenseSaaSOption}
                                        />
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                    <div>{selectedCard.firstName} {selectedCard.lastName} {selectedCard.email} {selectedCard.currentWorkspace}</div>
                    {/* <TableContainer sx={{ maxHeight: 440 }}>
                        <Table aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Product Name
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Domain
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Licenses
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Interval
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Cost/month
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Due Date
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Auto Renew
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        style={{ top: 57, minWidth: 150, background: '#F2F1EB', fontSize: '17px', fontWeight: 'bold' }}
                                    >
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedCard['data'] && selectedCard['data'].map((card, i) => (
                                        <TableRow role="checkbox" key={i}>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {card['productName']}
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {card['domain']}
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {card['noOfLicences']} Licences
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {card['interval'].charAt(0).toUpperCase() + card['interval'].slice(1)}
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {card['chargePerMonth']} {card['currency']}
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {
                                                    card['active'] === 'true' ? 'Active' :
                                                        card['active'] === 'overDue' ? 'Over Due' :
                                                            card['active'] === 'false' ? 'Expired' :
                                                                card['active'] === 'upcoming' ? 'Upcoming' : ''
                                                }
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                {convertUnixTimestampToDate(card['dueDate'])}
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                <Switch sx={{ mb: 1.5 }} checked={card['autoRenew'] === 'true'} onChange={() => { autoRenewHandler(card['subId'], card['autoRenew']) }} name="auto_renew" />
                                            </TableCell>
                                            <TableCell align='left' style={{fontSize: '15px'}}>
                                                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '2'}}>
                                                    <Button sx={{ fontSize: '12px', background: '#7953FF', color: 'white', ':hover': { background: '#7953FF!important' }, marginBottom: '3px' }} onClick={() => { handleOpenEditDialog(card) }}>
                                                        Add Licences
                                                    </Button>
                                                    <Button sx={{ fontSize: '12px', background: '#ffffff', color: 'black', border: '0.25px solid', ':hover': { background: '#ffffff!important' } }} onClick={() => { handleOpenRemoveDialog(card) }}>
                                                        Remove Licences
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </div>
            }
            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default MyAppsFunctions;